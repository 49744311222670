import React from "react";
import { createRoot } from "react-dom/client";
import DivisionShow from "../components/DivisionShow";

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("division-show");
  if (element) {
    const division = JSON.parse(element.dataset.division);
    createRoot(element).render(<DivisionShow division={division} />);
  }
});
