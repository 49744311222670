import React from "react";
import { useState, useEffect } from "react";

const DivisionShow = ({ division }) => {
  const [locale, setLocale] = useState("en");

  useEffect(() => {
    const userLocale = document.documentElement.lang || "en";
    setLocale(userLocale);
  }, []);

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <p className="text-lg text-gray-700">
        {locale === "ar" ? (
          <span dangerouslySetInnerHTML={{ __html: division.description }} />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: division.en_description }} />
        )}
      </p>
    </div>
  );
};

export default DivisionShow;
